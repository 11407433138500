import { IConfig, IEnv, IFirebaseClientCredentials } from './types'

const firebaseClientCredentials: IFirebaseClientCredentials = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY as string,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN as string,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID as string,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET as string,
  messagingSenderId: process.env
    .NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID as string,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID as string
}

/**
 * Config
 */

const env: IEnv = process.env.NEXT_PUBLIC_ENV as IEnv

const config: IConfig = {
  env,
  version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA as string,
  isProd: env === IEnv.Prod,
  isLocal: env === IEnv.Local,
  isServerSide: typeof window === 'undefined',
  clientSecret: process.env.CLIENT_SECRET as string,
  baseUrl: process.env.NEXT_PUBLIC_BASE_URL as string,
  serverBaseUrl: process.env.NEXT_PUBLIC_SERVER_BASE_URL as string,
  cronSecret: process.env.CRON_SECRET as string,
  graphqlUrl: process.env.NEXT_PUBLIC_GRAPHQL_URL as string,

  logger: {
    apiKey: process.env.NEXT_PUBLIC_LOG_FLARE_API_KEY as string,
    sourceToken: process.env.NEXT_PUBLIC_LOG_FLARE_SOURCE_TOKEN as string
  },

  datadog: {
    apiKey: process.env.DD_API_KEY as string,
    clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN as string
  },

  firebaseClientCredentials,
  googleWebClientId: process.env.NEXT_PUBLIC_GOOGLE_WEB_CLIENT_ID as string,
  recaptcha: {
    siteKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY as string,
    secretKey: process.env.RECAPTCHA_SECRET_KEY as string
  },

  pixels: {
    ga: env === IEnv.Prod ? 'AW-11007464981' : 'G-0LRWXEFLDH',
    tag: 'GTM-5G7FXSB',
    clarity: 'dbffqv5ycd',
    fb: '797173148217215'
  },

  hygraph: {
    url: process.env.NEXT_PUBLIC_HYGRAPH_CONTENT_URL as string,
    apiKey: process.env.HYGRAPH_AUTH_TOKEN as string
  }
}

export default config
