type IFirebaseClientCredentials = {
  apiKey: string
  authDomain: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
}

export enum IEnv {
  Local = 'local',
  Dev = 'development',
  Prod = 'production'
}

type IConfig = {
  env: IEnv
  version: string
  isProd: boolean
  isLocal: boolean
  isServerSide: boolean
  clientSecret: string
  graphqlUrl: string
  baseUrl: string
  serverBaseUrl: string
  cronSecret: string

  logger: {
    apiKey: string
    sourceToken: string
  }

  datadog: {
    apiKey: string
    clientToken: string
  }

  firebaseClientCredentials: IFirebaseClientCredentials
  googleWebClientId: string
  recaptcha: {
    siteKey: string
    secretKey: string
  }

  pixels: {
    ga: string
    tag: string
    clarity: string
    fb: string
  }

  hygraph: {
    url: string
    apiKey: string
  }
}

export type { IConfig, IFirebaseClientCredentials }
